<template>
    <div>
		<div class="header">
			<img class="logo-empresa" :src="reporteCompra.imagen" alt="logo"/>
			<span class="titulo-empresa">{{ reporteCompra.empresa }}</span>
			<span class="direccion">{{ reporteCompra.direccion }}</span>
			<span class="telefono">Telefono: {{ reporteCompra.telefono }}</span>
			<span class="email">Email: {{ reporteCompra.correo }}</span>
			<span class="ubicacion">Ciudad: {{ reporteCompra.ubicacion }}</span>

			<div class="textoborde">
				<p class="no-margin fechareporte"><strong>Fecha Reporte: </strong> {{ reporteCompra.fechaReporte }}</p>
				<p class="no-margin horareporte"><strong>Hora Reporte: </strong> {{ reporteCompra.horaReporte }}</p>
				<p class="no-margin usuario"><strong>Usuario: </strong> {{ reporteCompra.usuario }}</p>
				<p class="no-margin almacen"><strong>{{ reporteCompra.almacen }}</strong></p>
			</div>

			<span class="documento">REPORTE DE COMPRAS POR PRODUCTO</span>
			<span class="documentoReporte">del {{ reporteCompra.fecha_inicio }} al {{ reporteCompra.fecha_final }}</span>
		</div>

		<div class="footer">
			<center>
				<div class="css-no_print" align="center">
					<a-button onclick="print_default_printer()" type="primary">
						<a-icon type="printer" /> IMPRIMIR REPORTE
					</a-button>
				</div>
			</center>
		</div>

		<table>
			<thead><tr><th><div class="t-head">&nbsp;</div></th></tr></thead>
			<tbody><tr><td><div class="content">

                <div v-for="(item, index) in reporteCompra.compras" :key="index">
                    <div class="encabezado">
                        <div class="columna">Codigo: {{ item.codigoCompra }}</div>
                        <div class="columna">Fecha Compra: {{ item.fechaCompra }} {{ item.horaCompra }}</div>
                    </div>

                    <table class="table table-condensed table-striped">
                        <thead>
                            <tr>
                                <th style="width: 10%; font-size:11px;">CODIGO</th>
                                <th style="width: 40%; font-size:11px;">NOMBRE</th>
                                <th style="width: 10%; font-size:11px;">MEDIDA</th>
                                <th style="width: 7%; font-size:11px; text-align: center;">P.COMPRA</th>
                                <th style="width: 7%; font-size:11px; text-align: center;">CANT</th>
                                <th style="width: 7%; font-size:11px; text-align: center;">DESC</th>
                                <th style="width: 7%; font-size:11px; text-align: center;">SUBTOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, index) in item.listacompras" :key="index+1">
                                <td style="width: 10%;" class="tabla-listado">{{ value.codigo }}</td>
                                <td style="width: 40%;" class="tabla-listado">{{ value.nombre }}</td>
                                <td style="width: 10%;" class="tabla-listado">{{ value.medida }}</td>
                                <td style="width: 7%; text-align: center;" class="tabla-listado">{{ value.costo }}</td>
                                <td style="width: 7%; text-align: center;" class="tabla-listado">{{ value.cantidad }}</td>
                                <td style="width: 7%; text-align: center;" class="tabla-listado">{{ value.descuento }}</td>
                                <td style="width: 7%; text-align: center;" class="tabla-listado">{{ value.subtotal }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

				<center><span style="font-weight:400; font-size:10px; color:black;">Reporte generado por www.efectivo.io </span></center>

			</div></td></tr></tbody>
			<tfoot><tr><td><div class="t-foot">&nbsp;</div></td></tr></tfoot>
		</table>
    </div>
</template>

<script>
  export default {
      props: {
          reporteCompra: { type: Object, required: true }
      }
  }
</script>

<style scoped>
	@page {
		margin: 0mm;
		size: 218mm; 
		size: portrait;
	}

	@media print {
		body {border: 0;}
		thead {display: table-header-group;} 
		tfoot {display: table-footer-group;}
		button {display: none;}
		body {margin: 0;}
	}

	body {
		margin: 0 auto;
		font-family: 'Space Mono', monospace;
		max-width: 800px;
		background-color: #525252;
		width: 100%;
		border: 1px solid #ffff;
	}

	table {
        width: 100%;
    }

    .encabezado {
        display: flex;
        padding: 1px;
        font-weight: bold;
        background-color: #f2f2f2;
    }

    .columna {
        flex: 1;
        padding: 5px;
        border-bottom: 2px solid #ddd;
        text-align: center;
        font-size: 12px;
    }

    /* Estilo adicional para resaltar el encabezado */
    .encabezado .columna:first-child {
        text-align: left;
    }

    .encabezado .columna:last-child {
        text-align: right;
    }
	.tabla-listado {
		background-color: #ffffff; 
		border-top: 0px solid #ffffff; 
		padding: 5px; 
		border-bottom: 1px solid rgb(180, 180, 180);
		font-size: 10px;
	}
	.header, .t-head, .footer, .t-foot {
		height: 100px; max-width: 803px; width: 100%;
		background: #fff;
	}

	.footer, .t-foot { height: 50px; }
	.t-head, .t-foot { background: #fff; }
	.pr {
	position: fixed; right: 0; bottom: 10px;
	color: #aaa; letter-spacing: 1px;
	font-weight: normal; text-decoration: none;
	}

	.header{
		background: #fcfcfc;
		position: fixed; 
		top: 0;
		border-bottom: 1px solid #ffff;
	}
	.footer {
		background: #fcfcfc;
		position: fixed; 
		bottom: 0;
		border-top: 1px solid #ffff;
	}
	.content {
		background: #fefefe;
		padding: 10px;
	}

	.content .page {
		page-break-after: always;
	}
	.logo-empresa{
		position:absolute;
		width:80px;
		height:auto;
		left : 10px;
		z-index: 1;
		top:10px;
	}
	.titulo-empresa{
		position:absolute;
		font-size: 11px;
		color:black;
		font-weight:700;
		letter-spacing: 0.50;
		left : 97px;
		z-index: 1;
		top: 12px;
	}

	.direccion{
		position:absolute;
		font-size: 9px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 28px;
	}
	.telefono{
		position:absolute;
		font-size: 8px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 42px;
	}

	.celular{
		position:absolute;
		font-size: 8px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 56px;
	}
	.email{
		position:absolute;
		font-size: 8px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 55px;
	}

	.ubicacion{
		position:absolute;
		font-size: 9px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 68px;
	}
	.textoborde {
		position:absolute;
		width: 170px;
		height: 70px;
		background-color:#F5F5F5;
		border:1.2px solid #DDDDDD;  
		text-align: right;
		line-height : 1px;
		overflow:hidden;
		padding:5px;
		-webkit-border-radius: 4px;
		border-radius: 4px;
		left : 590px;
		z-index: 1;
		top:20px;
		padding-top: 5px;
		padding-right: 5px;
		padding-bottom: 0px;
		padding-left: 5px;
	}

	.fechareporte{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 10px;
	}
	.horareporte{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 23px;
	}

	.usuario{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 38px;
	}
	.almacen{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 53px;
	}
	.css-data_table{
		width: 100%;
		border: none;
		border-collapse: collapse;
		border-spacing: 2px 0px;
	}
				
	.css-data_table th {
		border-bottom: 1px solid rgb(14, 14, 14);      
		font-weight: 700;
		text-align: center;
		font-size: 0.9em;
		font-weight:700;
	}
	.css-data_table td{
		border: 1px solid black;
		padding: 5px;
		text-align: center;
		font-size: 0.6em;
		font-weight:400;
	}
	.documento{
		position:absolute;
		font-size: 16px;
		font-weight:700;
		color:black;
		left : 230px;
		z-index: 1;
		top: 50px;
	}
	.documentoReporte{
		position:absolute;
		font-size: 15px;
		font-weight:700;
		color:black;
		left : 288px;
		z-index: 1;
		top: 75px;
	}
	.fecha{
		position:absolute;
		font-size: 13px;
		font-weight:40;
		color:black;
		left : 320px;
		z-index: 1;
		top: 60px;
	}
</style>