<template>
    <div>
		<template v-if="reporteCompra.tipo === 'reporte-compra'">
			<ReporteCompra
				:reporteCompra="reporteCompra"
			/>
		</template>

		<template v-if="reporteCompra.tipo === 'reporte-usuario'">
			<ReporteUsuario
				:reporteCompra="reporteCompra"
			/>
		</template>

		<template v-if="reporteCompra.tipo === 'reporte-proveedor'">
			<ReporteProveedor
				:reporteCompra="reporteCompra"
			/>
		</template>

		<template v-if="reporteCompra.tipo === 'reporte-producto'">
			<ReporteProducto
				:reporteCompra="reporteCompra"
			/>
		</template>

		<template v-if="reporteCompra.tipo === 'reporte-categoria'">
			<ReporteCategoria
				:reporteCompra="reporteCompra"
			/>
		</template>

		<template v-if="reporteCompra.tipo === 'reporte-pagar'">
			<ReportePagar
				:reporteCompra="reporteCompra"
			/>
		</template>

		<template v-if="reporteCompra.tipo === 'reporte-anulado'">
			<ReporteAnulado
				:reporteCompra="reporteCompra"
			/>
		</template>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.9); z-index: 1020;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as utils from '@/utils/utils'
  import router from '@/config/router'
  import ReporteCompra from './Modulo/ReporteCompra.vue'
  import ReporteUsuario from './Modulo/ReporteUsuario.vue'
  import ReporteProveedor from './Modulo/ReporteProveedor.vue'
  import ReporteProducto from './Modulo/ReporteProducto.vue'
  import ReporteCategoria from './Modulo/ReporteCategoria.vue'
  import ReportePagar from './Modulo/ReportePagar.vue'
  import ReporteAnulado from './Modulo/ReporteAnulado.vue'

  export default {

	  components: {
	      ReporteCompra,
		  ReporteUsuario,
		  ReporteProveedor,
		  ReporteProducto,
		  ReporteCategoria,
		  ReportePagar,
		  ReporteAnulado
	  },

      data () {
          return {
			 utils,
			 spinnerloading: false
          }
      }, 

      computed: {
          ...mapGetters('auth', ['user']),
          ...mapGetters('estadistica', ['reporteCompra'])
      },

      methods: {
		  ...mapActions('estadistica', ['descargarReporteCompra']),

		  descargarCompra () {
			  this.spinnerloading = true
			  const consulta = this.$route.query
              this.descargarReporteCompra(consulta)
			  .then(response => {
				 this.spinnerloading = false
			  })
			  .catch(error => {
				this.spinnerloading = false
				alert(utils.showErrors(error.response.data))
				window.close()
			  	utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
			  })		 
 		  }
      },

	  mounted () {
		  this.descargarCompra()
	  }
  }
</script>